<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-user-clock" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="trip-title">{{event.title}}</div>
            <div class="meta" v-if="!event.time && event.start_at && event.end_at"><i class="far fa-clock fa-fw mr-2"></i>{{event.start_at | moment('timezone', event.start_tz, 'h:mm A')}} - {{event.end_at | moment('timezone', event.end_tz, 'h:mm A')}}</div>
            <div class="meta" v-if="event.time"><i class="far fa-clock fa-fw mr-2"></i>{{event.time}}</div>
            <div class="meta"><i class="far fa-map-marker fa-fw mr-2"></i>{{event.location}}</div>
        </template>
        <template v-slot:status>
            <div class="stats">
                <b-tag type="is-warning" class="stat" v-if="!event.task_complete"><i class="fas fa-tasks"></i></b-tag>
            </div>
        </template>
        <template v-slot:details>
            <div class="details-rollout">
                <toolbar class="toolbar">
                    <button class="icon-only" @click.stop=""><i class="far fa-pencil"></i></button>
                </toolbar>

                <div class="data mt-1">
                    <data-row mini title="Active">{{event.active}}</data-row>
                    <data-row mini title="Local Start" v-if="event.start_at">{{event.start_at | moment('timezone', event.start_tz, 'h:mm A')}}</data-row>
                    <data-row mini title="Local End" v-if="event.end_at">{{event.end_at | moment('timezone', event.end_tz, 'h:mm A')}}</data-row>
                    <data-row mini title="Location">{{event.location}}</data-row>
                    <data-row mini title="Type">{{event.type}}</data-row>
                    <data-row mini title="ID">{{event.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'CustomEventListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            event: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .trip-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }
    }
</style>
